<template>
  <mdb-container class="container">
    <div class="text-center mb-5"><img src="@/assets/images/partner/logo-assetta.png" alt=""></div>
    <mdb-row center>
      <mdb-col sm="5">
        <mdb-card>
          <mdb-card-body>
            <!-- Material form login -->
            <form>
              <p class="h4 text-center mb-4">폴리스랩2.0 관리자 로그인</p>
              <div class="grey-text">
                <mdb-input label="이메일" icon="envelope" type="email" v-model="email_id"/>
                <mdb-input label="패스워드" icon="lock" type="password" v-model="password"/>
              </div>
              <div class="text-center">
                <mdb-btn @click="login">Login</mdb-btn>
              </div>
            </form>
            <!-- Material form login -->
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>
<script>
import {mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn, mdbCardBody, mdbCard} from 'mdbvue';
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: 'Login',
  data() {
    return {
      email_id: '',
      password: '',
    }
  },
  components: {
    mdbInput,
    mdbBtn,
    mdbCardBody,
    mdbCard, mdbContainer, mdbRow, mdbCol,
  },
  methods: {
    login() {
      const self = this;
      const id = self.email_id;
      const password = self.password;

      firebase.auth().signInWithEmailAndPassword(id, password).then((result) => {
        console.log(result.user)
        self.$router.replace('/admin');
      }).catch((err) => {
        // 실패 후 실행할 코드
        alert(err);
      })
    }
  }
}
</script>

<style scoped>

.container {
  margin-top: 150px;
  font-family: monospace;
}

.form-control{
  color: black;
}
</style>
